import './App.scss';
import Main from './components/MainComponent';

function App() {
  return (
    <Main />
  );
}

export default App;
