import React from "react";
import { Row, Col } from 'reactstrap';

function About () {
    return (
        <section id="about">
            <Row>
                <Col md className="col-md-4 p-0" id="about-left">
                    <div id="about-bg"></div>
                </Col>
                <Col md className="col-md-8" id="about-right">
                    <div className="my-5" id="about-content">
                        <h4><strong>About Us</strong></h4>
                        <h6><strong>Our REDPANDA trading company was established in 2013. We have always handled only genuine products and have grown on the basis of trust and responsibility with our customers.</strong></h6>
                        <p>We hope that our valued relationship with all our customers will last for a long time.
                            <br/><br/> If you have any question, please contact us on the website, or send a message directly to our various contacts of below.
                            <br/><br/> Thank you.</p>
                    </div>
                </Col>
            </Row>
        </section>
    )
}

export default About;